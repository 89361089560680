<template>
    <div v-show="visible" class="drawer-container">
      <div>
        <div class="setting-drawer-content">
          <div class="drawer-item">
            <span>主题颜色</span>
            <theme-picker
              style="float: right;height: 26px;margin: -3px 8px 0 0;"
              @change="themeChange"
            />
          </div>
        </div>

        <el-divider />

        <h3 class="drawer-title">系统布局配置</h3>

        <div class="drawer-item">
          <span>开启 Tags-Views</span>
          <el-switch v-model="tagsView" class="drawer-switch" />
        </div>

        <div class="drawer-item">
          <span>固定 Header</span>
          <el-switch v-model="fixedHeader" class="drawer-switch" />
        </div>

        <div class="drawer-item">
          <span>显示 Logo</span>
          <el-switch v-model="sidebarLogo" class="drawer-switch" />
        </div>

        <el-divider />

        <el-button
          type="primary"
          plain
          icon="el-icon-document-add"
          @click="saveSetting"
          >保存配置</el-button
        >
        <el-button
          plain
          icon="el-icon-refresh"
          @click="resetSetting"
          >重置配置</el-button
        >
      </div>
    </div>
  <!-- <el-drawer
      size="280px"
      :visible="visible"
      :with-header="false"
      :append-to-body="true"
      :show-close="false"
    >
    </el-drawer> -->
</template>

<script>
import ThemePicker from "@/components/ThemePicker";

export default {
  name: "setting",
  components: { ThemePicker },
  data() {
    return {
      theme: this.$store.state.settings.theme,
    };
  },
  computed: {
    visible: {
      get() {
        return this.$store.state.settings.showSettings;
      },
    },
    fixedHeader: {
      get() {
        return this.$store.state.settings.fixedHeader;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "fixedHeader",
          value: val,
        });
      },
    },
    tagsView: {
      get() {
        return this.$store.state.settings.tagsView;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "tagsView",
          value: val,
        });
      },
    },
    sidebarLogo: {
      get() {
        return this.$store.state.settings.sidebarLogo;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "sidebarLogo",
          value: val,
        });
      },
    },
  },
  methods: {
    handleClick() {
      this.$store.dispatch("settings/changeSetting", {
        key: "showSettings",
        value: false,
      });
    },
    themeChange(val) {
      this.$store.dispatch("settings/changeSetting", {
        key: "theme",
        value: val,
      });
      this.theme = val;
    },
    saveSetting() {
      this.$modal.loading("正在保存到本地，请稍候...");
      this.$cache.local.set(
        "layout-setting",
        `{
            "tagsView":${this.tagsView},
            "fixedHeader":${this.fixedHeader},
            "sidebarLogo":${this.sidebarLogo},
            "theme":"${this.theme}"
          }`
      );
      setTimeout(this.$modal.closeLoading(), 1000);
      this.handleClick();
    },
    resetSetting() {
      this.$modal.loading("正在清除设置缓存并刷新，请稍候...");
      this.$cache.local.remove("layout-setting");
      setTimeout("window.location.reload()", 1000);
      this.handleClick();
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-drawer-content {
  .setting-drawer-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
  }

  .setting-drawer-block-checbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;

    .setting-drawer-block-checbox-item {
      position: relative;
      margin-right: 16px;
      border-radius: 2px;
      cursor: pointer;

      img {
        width: 48px;
        height: 48px;
      }

      .setting-drawer-block-checbox-selectIcon {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding-top: 15px;
        padding-left: 24px;
        color: #1890ff;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}

.drawer-container {
  position: relative;
  padding: 20px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;

  .drawer-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 22px;
  }

  .drawer-item {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    padding: 12px 0;
  }

  .drawer-switch {
    float: right;
  }
}
</style>
