var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "app-main",
      style: {
        height: _vm.fixedHeader
          ? "calc(100vh)"
          : _vm.needTagsView
          ? "calc(100vh - 84px)"
          : "calc(100vh - 50px)",
        "--padding-top": _vm.needTagsView ? "80px" : "50px",
      },
    },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in", appear: "" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachedViews } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }