var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "notification" },
    [
      _setup.warnMessList.length > 0
        ? _c(
            "div",
            _vm._l(_setup.warnMessList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "notice-container",
                  style: {
                    zIndex: index + 1,
                    boxShadow:
                      index == _setup.warnMessList.length - 1
                        ? "0px 4px 24px 0px rgba(0, 0, 0, 0.08)"
                        : "",
                  },
                },
                [
                  _vm._m(0, true),
                  _vm._v(" "),
                  _c("div", { staticClass: "list-data" }, [
                    _c("div", [
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.level == 1,
                            expression: "item.level == 1",
                          },
                        ],
                        staticClass: "notice-icon",
                        attrs: {
                          src: require("@/assets/message/urgent-mess.svg"),
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.level == 2,
                            expression: "item.level == 2",
                          },
                        ],
                        staticClass: "notice-icon",
                        attrs: {
                          src: require("@/assets/message/serious-mess.svg"),
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.level == 3,
                            expression: "item.level == 3",
                          },
                        ],
                        staticClass: "notice-icon",
                        attrs: {
                          src: require("@/assets/message/warn-mess.svg"),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notice-items" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-bottom": "12px",
                            "border-bottom": "1px solid #F2F3F5",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("div", { staticClass: "item-name" }, [
                                _vm._v(_vm._s(item.alertName)),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                item.level == 1
                                  ? _c("div", { staticClass: "is-state1" }, [
                                      _vm._v("紧急"),
                                    ])
                                  : item.level == 2
                                  ? _c("div", { staticClass: "is-state2" }, [
                                      _vm._v("严重"),
                                    ])
                                  : _c("div", { staticClass: "is-state3" }, [
                                      _vm._v("一般"),
                                    ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "item-time" }, [
                            _vm._v(
                              "\n                            时间：" +
                                _vm._s(item.createTime) +
                                "\n                        "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticStyle: { "padding-top": "12px" } }, [
                        _c("div", { staticClass: "item-left" }, [
                          _vm._v("\n                            充电站名称 "),
                          _c("span", { staticClass: "item-right" }, [
                            _vm._v(_vm._s(item.plotName)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item-left" }, [
                          _vm._v(
                            "\n                            设备类型\n                            "
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.deviceType == 1,
                                  expression: "item.deviceType == 1",
                                },
                              ],
                              staticClass: "item-right",
                            },
                            [_vm._v("充电桩")]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.deviceType == 2,
                                  expression: "item.deviceType == 2",
                                },
                              ],
                              staticClass: "item-right",
                            },
                            [_vm._v("充电枪")]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.deviceType == 3,
                                  expression: "item.deviceType == 3",
                                },
                              ],
                              staticClass: "item-right",
                            },
                            [_vm._v("电表")]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.deviceType == 4,
                                  expression: "item.deviceType == 4",
                                },
                              ],
                              staticClass: "item-right",
                            },
                            [_vm._v("其他")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "item-left",
                            staticStyle: {
                              width: "280px",
                              overflow: "hidden",
                              "white-space": "nowrap",
                              "text-overflow": "ellipsis",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            设备名称\n                            "
                            ),
                            _c("span", { staticClass: "item-right" }, [
                              _vm._v(_vm._s(item.deviceName)),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "warn-btn",
                      staticStyle: {
                        "padding-top": "12px",
                        "border-top": "1px solid #F2F3F5",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "warn-close",
                          on: {
                            click: function ($event) {
                              return _setup.getReadAlertFn(item, index)
                            },
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "warn-know" },
                        [
                          _c("router-link", { attrs: { to: `/message` } }, [
                            _vm._v(
                              "\n                        查看\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _setup.dialogVisibleImage,
            title: "消息通知图片预览",
          },
          on: {
            "update:visible": function ($event) {
              _setup.dialogVisibleImage = $event
            },
          },
        },
        [
          _c(
            "el-carousel",
            {
              staticClass: "image-swiper",
              attrs: {
                height: "100%",
                "indicator-position":
                  _setup.currentImages.length <= 1 ? "none" : "",
                arrow: _setup.currentImages.length <= 1 ? "never" : "",
                autoplay: false,
              },
            },
            _vm._l(_setup.currentImages, function (image, $i) {
              return _c(
                "el-carousel-item",
                { key: $i },
                [
                  _c("el-image", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: {
                      src: image,
                      "preview-src-list": _setup.currentImages.map((v) => v),
                      "initial-index": $i,
                      fit: "cover",
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _setup.dialogVisibleImage = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "notice-header" }, [
      _c("img", {
        staticClass: "notice-icon",
        staticStyle: { "margin-right": "4px" },
        attrs: { src: require("@/assets/message/safe1.svg") },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "wrapper-text" }, [_vm._v("告警通知")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }