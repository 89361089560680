<template>
  <div class="navbar">
    <showMessage v-if="isNotificationVisible" :message="notificationMessage" type="success" />
    <div class="left-menu">
      <hamburger style="padding-left: 0px" id="hamburger-container" :is-active="sidebar.opened"
        class="hamburger-container" @toggleClick="toggleSideBar" />
      <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    </div>
    <div class="right-menu">
      <search id="header-search" class="right-menu-item" />
      <Screenfull id="screenfull" class="right-menu-item hover-effect" />
      <!-- <svg-icon icon-class="nav-text" class-name="svg-box"></svg-icon> -->
      <!-- notice消息通知 -->

      <div>
        <div :style="{ marginRight: noticeVal == 0 ? '14px' : '7px' }" style="margin-right: 7px;cursor: pointer;">
          <router-link :to="`/message`">
            <img src="@/assets/message/notification.svg" class="notice-icon" />
            <el-badge v-if="noticeVal != 0" :value="noticeVal" :max="99" class="notice-nums"></el-badge>
          </router-link>
        </div>
      </div>

      <el-tooltip content="布局大小" effect="dark" placement="bottom">
        <size-select id="size-select" class="right-menu-item hover-effect" />
      </el-tooltip>
      <!-- <svg-icon icon-class="nav-lang" class-name="svg-box"></svg-icon> -->
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <div><i class="el-icon-arrow-down" />&nbsp;&nbsp;{{ adminName }}</div>
          <img src="../../assets/touxiang.png" class="user-avatar" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item divided>
            <span style="display:block;" @click="show()">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided>
            <span style="display:block;" @click="logout">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog :close-on-click-modal="false" title="修改密码" :modal="false" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="formData" :rules="formRule">
        <el-form-item class="nav-form-item" label="原密码" prop="password">
          <el-input type="password" v-model="formData.password" style="width:300px;" placeholder="请输入原密码" clearable />
        </el-form-item>
        <el-form-item class="nav-form-item" label="新密码" prop="password">
          <el-input type="password" v-model="formData.newPassword" style="width:300px;" placeholder="请输入新密码"
            clearable />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confim">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import { mapGetters } from 'vuex';
import { changePwd } from '@/api/user';
import { getToken, getName } from '@/utils/auth';
import Hamburger from '@/components/Hamburger';
import Search from '@/components/HeaderSearch';
import Screenfull from '@/components/Screenfull';
import SizeSelect from '@/components/SizeSelect';
import ErrorLog from '@/components/ErrorLog';
import { getInfoByKey } from '@/api/config';
import { getMessageCenterUnread, getMessageCenterNews, getMessageCenterAlert } from '@/api/message';
import { Notification } from 'element-ui';
import showMessage from './showMessage/index.vue';

export default {
  data() {
    return {
      title: '',
      dlogo: this.global.apiUrl + 'dlogo.png',
      adminName: '',
      formData: {
        password: '',
        newPassword: '',
      },
      dialogVisible: false,
      formRule: {
        password: [
          { required: true, message: '请输入必填项', trigger: 'blur' },
        ],
      },
      messIcon: require('@/assets/message/email.svg'),
      noticeVal: 0,
      warnMessList: [],
      platformList: [],
      isNotificationVisible: true,
      notificationMessage: 'This is a notification.'
    };
  },
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    SizeSelect,
    ErrorLog,
    Search,
    showMessage
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'device']),
  },

  methods: {
    getMessageCenterUnreadFn() { //获取未读消息
      getMessageCenterUnread({}).then((res) => {
        if (res.message.code == 0) {
          this.noticeVal = res.data
        }
      });
    },
    getMessageCenterNewsFn() { //获取消息中心-平台消息
      getMessageCenterNews({}).then((res) => {
        if (res.message.code == 0) {
          this.platformList = res.data

          this.platformList.forEach(item => {
            setTimeout(() => {
              Notification.info({
                title: '',
                dangerouslyUseHTMLString: true,
                showClose: false,
                duration: 0,
                iconClass: '',
                message: `<div class="platform-body" style="width: 360px;padding: 4px 16px;">
                          <div class="platform-header" style=" display: flex;align-items: center;padding-bottom: 8px;border-bottom: 1px solid #F2F3F5;color: #86909C;font-family: "PingFang SC";font-size: 14px;font-weight: 400;">
                            <img src="${this.messIcon}" class="notice-icon" /> 平台消息
                          </div>
                          <div class="platform-container" style="padding: 12px 0;">
                            <div class="platform-title" style="overflow: hidden;color: #1D2129;text-overflow: ellipsis;font-family: "PingFang SC";font-size: 16px;font-weight: 500;line-height: 24px;">
                            ${item.title} 
                            </div>
                            <div class="platform-content" style="padding-top: 4px;color:  #4E5969;font-family: "PingFang SC";font-size: 14px;font-weight: 400;line-height: 22px;">
                              <div v-html="${item.content}"></div>
                            </div>
                          </div>
                          <div class="platform-btn" style="padding-top: 12px;text-align: right;border-top: 1px solid #F2F3F5;">
                            <div @click="handleCloseNotice" size="small">知道了</div>
                          </div>
                        </div>`
              })
            }, 500)
          })
        }
      });
    },
    getMessageCenterAlertFn() { //获取消息中心-告警信息
      getMessageCenterAlert({}).then((res) => {
        if (res.message.code == 0) {
          this.warnMessList = res.data
        }
      });
    },
    handleCloseNotice() {
      console.log(1231);
      
      // this.$refs.noticeDropdownRef.hide();
    },
    handleReadDetail() {
      // this.$route.push('')
    },
    handleClickOutside() {
      this.$emit('toggleClick');
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      await this.$store.dispatch('user/logout');
      location.href = `#/login`;
      // location.href = `#/login?redirect=${this.$route.fullPath}`;
      //  this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    confim() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {
            password: this.formData.password,
            newPassword: this.formData.newPassword,
            token: getToken(),
          };
          changePwd(data).then((res) => {
            if (res.message.code == 0) {
              this.$message({
                message: '修改成功，请重新登录',
                type: 'success',
              });
              this.logout();
            } else {
              this.$message.error(res.data);
            }
          });
        }
      });
    },
    show() {
      this.formData.password = '';
      this.formData.newPassword = '';
      this.dialogVisible = true;
    },

    getConfigData(value) {
      let param = {
        configKey: value,
      };
      getInfoByKey(param).then((res) => {
        if (res.data) {
          this.title = res.data;
        }
      });
    },
  },
  created() {
    this.adminName = this.$store.state.user.name;
    this.getConfigData('sys_title');

    this.getMessageCenterUnreadFn() 
    // this.getMessageCenterNewsFn()
    // this.getMessageCenterAlertFn()
  },
};
</script>

<style lang="scss" scoped>
// #081220
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .left-menu {
    left: 0;
    display: flex;
    align-items: center;
    padding: 0 20px;

    img {
      height: 30px;
      width: 71px;
    }
  }

  .right-menu {
    height: 100%;
    display: flex;
    align-items: center;
    line-height: 50px;

    .svg-box {
      font-size: 20px;
      margin-right: 16px;
    }

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      margin-right: 16px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        color: #5a5e66;
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .notice-icon {
      width: 1.4em;
      height: 1.4em;
      fill: currentColor;
      vertical-align: -0.28em;
    }

    .notice-nums {
      margin-left: -10px;
      margin-top: -14px;
    }

    .avatar-container {
      margin-right: 30px;
      height: 100%;

      .avatar-wrapper {
        position: relative;
        height: 100%;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        align-items: center;
        color: #666666;
        font-weight: 500;
        cursor: pointer;

        div:nth-child(1) {
          margin-right: 20px;
          text-wrap: nowrap;
        }

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}

// 消息-告警
.notice-container {
  padding: 12px 16px 6px;

  .notice-header {
    display: flex;
    padding-bottom: 8px;
    border-bottom: 1px solid #f2f3f5;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .notice-content {
    display: flex;
  }

  .notice-items {
    margin-left: 10px;
  }

  .wrapper-text {
    color: #86909c;
    font-family: 'PingFang SC';
    font-size: 14px;
    font-weight: 400;
  }

  .list-data {
    display: flex;
    padding: 12px 0;
    border-bottom: 1px solid #f2f3f5;
  }

  .item-name {
    color: #1d2129;
    font-family: 'PingFang SC';
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  .item-state {
    margin-left: 8px;
    padding: 0 4px;
    border-radius: 4px;
    border: 1px solid #057c98;
    color: #057c98;
    font-family: 'PingFang SC';
    font-size: 12px;
    font-weight: 400;
  }

  .item-time {
    color: var(--PC-, #4e5969);
    font-family: 'PingFang SC';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .item-left {
    color: var(--PC-, #86909c);
    font-family: 'PingFang SC';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .item-right {
    overflow: hidden;
    color: var(--PC-, #1d2129);
    text-overflow: ellipsis;
    font-family: 'PingFang SC';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

// 平台消息
.platform-body {
  width: 360px;
  padding: 4px 16px;

  .platform-header {
    img {
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }

    display: flex;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--PC-, #F2F3F5);
    color: var(--PC-, #86909C);
    font-family: "PingFang SC";
    font-size: 14px;
    font-weight: 400;
  }

  .platform-container {
    padding: 12px 0;

    .platform-title {
      overflow: hidden;
      color: var(--PC-, #1D2129);
      text-overflow: ellipsis;
      font-family: "PingFang SC";
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .platform-content {
      padding-top: 4px;
      color: var(--PC-, #4E5969);
      font-family: "PingFang SC";
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .platform-btn {
    padding-top: 12px;
    text-align: right;
    border-top: 1px solid var(--PC-, #F2F3F5);
  }
}

::v-deep .el-notification__icon {
  display: none;
}
</style>
