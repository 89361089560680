<template>
    <div class="notification">
        <!-- 告警通知 -->
        <div v-if="warnMessList.length > 0">
            <div class="notice-container" v-for="(item, index) in warnMessList" :key="index"
                :style="{ zIndex: index + 1, boxShadow: index == warnMessList.length - 1 ? '0px 4px 24px 0px rgba(0, 0, 0, 0.08)' : '' }">
                <div class="notice-header">
                    <img src="@/assets/message/safe1.svg" class="notice-icon" style="margin-right: 4px;" />
                    <span class="wrapper-text">告警通知</span>
                </div>
                <div class="list-data">
                    <div>
                        <!-- level:告警级别(1:紧急 2:严重 3:一般) -->
                        <img v-show="item.level == 1" src="@/assets/message/urgent-mess.svg" class="notice-icon" />
                        <img v-show="item.level == 2" src="@/assets/message/serious-mess.svg" class="notice-icon" />
                        <img v-show="item.level == 3" src="@/assets/message/warn-mess.svg" class="notice-icon" />
                    </div>
                    <div class="notice-items">
                        <div style="padding-bottom: 12px;border-bottom: 1px solid #F2F3F5;">
                            <div style="display: flex;align-items: center;">
                                <div class="item-name">{{ item.alertName }}</div>
                                <div>
                                    <div class="is-state1" v-if="item.level == 1">紧急</div>
                                    <div class="is-state2" v-else-if="item.level == 2">严重</div>
                                    <div class="is-state3" v-else>一般</div>
                                </div>
                            </div>
                            <div class="item-time">
                                时间：{{ item.createTime }}
                            </div>
                        </div>
                        <div style="padding-top: 12px;">
                            <div class="item-left">
                                充电站名称 <span class="item-right">{{ item.plotName }}</span>
                            </div>
                            <div class="item-left">
                                设备类型
                                <span class="item-right" v-show="item.deviceType == 1">充电桩</span>
                                <span class="item-right" v-show="item.deviceType == 2">充电枪</span>
                                <span class="item-right" v-show="item.deviceType == 3">电表</span>
                                <span class="item-right" v-show="item.deviceType == 4">其他</span>
                            </div>
                            <div class="item-left"
                                style="width: 280px;overflow: hidden; white-space: nowrap;text-overflow: ellipsis;">
                                设备名称
                                <span class="item-right">{{ item.deviceName }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="warn-btn" style="padding-top: 12px;border-top: 1px solid #F2F3F5;">
                    <div @click="getReadAlertFn(item, index)" class="warn-close">关闭</div>
                    <div class="warn-know">
                        <router-link :to="`/message`">
                            查看
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- 平台消息 -->
        <!-- <div v-if="platformList.length > 0">
            <div class="platform-body slide-in-from-right" v-for="(item, index) in platformList" :key="index">
                <div class="platform-header">
                    <img src="@/assets/message/email.svg" class="notice-icon" /> 平台消息
                </div>
                <div class="platform-container">
                    <div class="platform-title">
                        {{ item.title }}
                    </div>
                    <div class="platform-content">
                        <div v-if="item.content && item.content != ''">
                            <div v-html="item.showContent.compiledHtml" style="padding-top: 8px;"></div>
                            <div class="image-gallery" style="margin-top: 8px;">
                                <img v-for="(image, index) in item.showContent.images"
                                    style="margin-right: 8px; width: 140px; height: 105px;border-radius: 4px;"
                                    :key="index" :src="image.src" :alt="image.alt" class="image-gallery-item">
                            </div>
                        </div>
                        <div v-else>-</div> 
                    </div>
                </div>
                <div class="platform-btn" style="padding-top: 12px;border-top: 1px solid #F2F3F5;">
                    <div @click="handleCloseNotice(item)" class="platform-know">知道了</div>
                </div>
            </div>
        </div> -->

        <!-- 预览图片 -->
        <el-dialog :visible.sync="dialogVisibleImage" title="消息通知图片预览">
            <el-carousel class="image-swiper" :height="'100%'"
                :indicator-position="currentImages.length <= 1 ? 'none' : ''"
                :arrow="currentImages.length <= 1 ? 'never' : ''" :autoplay="false">
                <el-carousel-item v-for="(image, $i) in currentImages" :key="$i">
                    <el-image style="width: 100%; height: 100%" :src="image"
                        :preview-src-list="currentImages.map(v => v)" :initial-index="$i" :fit="'cover'">
                    </el-image>
                </el-carousel-item>
            </el-carousel>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleImage = false">关 闭</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script setup>
import { ref  } from 'vue'
import { Message, MessageBox } from "element-ui";

import { getMessageCenterNews, getMessageCenterAlert, getNewsReadNews, getReadAlert } from '@/api/message';
import { usePolling1, usePolling2 } from '../hook';
import { getName } from '@/utils/auth.js'
//为0代表不可操作，1可操作属于运营人员
const getIsoperator = JSON.parse(getName())

const isOperator = ref(getIsoperator.userInfo.isOperator)

let warnMessList = ref([])
let platformList = ref([])
const currentImages = ref([])
const dialogVisibleImage = ref(false);
const platIcon = require('@/assets/message/email.svg')

// 轮询器
let { startPolling1, stopPolling1 } = usePolling1(getMessageCenterAlertFn, 300000, true);
let { startPolling2, stopPolling2 } = usePolling2(getMessageCenterNewsFn, 300000, true);
startPolling1();

if(isOperator.value == 1){ //运营
    startPolling2();
}

function getMessageCenterAlertFn() { //获取消息中心-告警信息
    getMessageCenterAlert({}).then((res) => {
        if (res.message.code == 0 && res.data.length > 0) {
            warnMessList.value = res.data
            setTimeout(() => {
                warnMessList.value = []
            },60000)
        }
    });
}

function getMessageCenterNewsFn() { //获取消息中心-平台消息
    getMessageCenterNews({}).then((res) => {
        if (res.message.code == 0 && res.data.length > 0) {
            res.data = res.data.filter(item => item.showContent = extractImages(item.content))
            res.data.forEach((item, index) => {
                setTimeout(() => {
                    openMessageBox(item)
                }, index * 1000); 
            })
        }
    });
}

function extractImages(htmlString) { //富文本处理
    // 使用 DOM 操作提取所有图片
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const getHandleData = {}
    //图片处理
    getHandleData.images = Array.from(doc.querySelectorAll('img')).map(img => ({
        src: img.src,
        alt: img.alt,
    }));
    //文本处理
    getHandleData.compiledHtml = doc.body.innerHTML.replace(/<[^>]+>/g, '')
    return getHandleData
}

function handleCloseNotice(item) { //已阅读
    getNewsReadNews({ userNewIds: item.id.toString().split(',') }).then((res) => {
        if (res.message.code == 0) {
            platformList.value = [] //先清空
            getMessageCenterNewsFn()
        }
    })
}

function getReadAlertFn(item, index) { //警告已读-关闭
    getReadAlert({ userAlertIds: item.eventId.toString().split(',') })
        .then((res) => {
            if (res.message.code == 0) {
                Message.success('操作成功')
                // warnMessList.value = []
                // getMessageCenterAlertFn()

                //当前清除
                warnMessList.value.splice(index, 1)
            } else {
                Message(res.message.message)
            }
        }).catch((e) => {
            Message(e)
        });
}


let array = [{
    content: "<p>割让<img src=\"https://pic3.zhimg.com/v2-5fb13110e1de13d4c11e6e7f5b8026da_r.jpg\"><img src=\"https://tse1-mm.cn.bing.net/th/id/OIP-C.cGjCuP5ghtV5SuGhFWIqUAHaHa?rs=1&pid=ImgDetMain\"></p>",
    createTime: null,
    id: 383,
    newsId: null,
    publishTime: "2024-09-04 10:54:13",
    readTime: null,
    remarks: null,
    stat: 0,
    title: "测试标题111111111111"
},
{
    content: "<p>割让<img src=\"https://tse4-mm.cn.bing.net/th/id/OIP-C.g9UbVfyVZX-SfD09JcYr5QHaEK?rs=1&pid=ImgDetMain\"><img src=\"https://img.zcool.cn/community/01e4315542ab990000019ae99f4ef0.jpg@1280w_1l_2o_100sh.jpg\"></p>",
    createTime: null,
    id: 383,
    newsId: null,
    publishTime: "2024-09-04 10:54:13",
    readTime: null,
    remarks: null,
    stat: 0,
    title: "测试标题2222222222"
}]

// 平台消息
function openMessageBox(item) {

    let imgHtml = ''
    if (item.showContent.images.length > 0) {
        item.showContent.images.forEach(showItem => {
            imgHtml += `<img style="margin-right: 8px; width: 140px; height: 105px;border-radius: 4px;" src="${showItem.src}" alt="${showItem.alt}" class="image-gallery-item" onclick="handlePreviewImages(item.showContent.images)" >`
        })
    }

    const elementHtml = `<div class="platform-body slide-in-from-right">
                <div class="platform-header" style="display: flex;align-items: center; padding-bottom: 8px;border-bottom: 1px solid var(--PC-, #F2F3F5);">
                    <img src="${platIcon}" class="notice-icon" style="margin-right:4px"/> 平台消息
                </div>
                <div class="platform-container" style="padding-top:12px;">
                    <div class="platform-title" style="overflow: hidden;color: #1D2129;text-overflow: ellipsis;font-size: 16px;
            font-weight: 500;line-height: 24px;">
                        ${item.title}
                    </div>
                    <div class="platform-content">
                        <div>
                            <div style="padding-top: 8px;">
                                ${item.showContent.compiledHtml}
                            </div>
                            <div class="image-gallery" style="margin-top: 8px;">
                                ${imgHtml}
                            </div>
                        </div>
                    </div>
                </div>
            </div>`;

    MessageBox({
        message: elementHtml,
        confirmButtonText: '知道了',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        duration: 3000
    });
    setTimeout(() => {// 60s关闭当前的消息框
        MessageBox.close(); 
    }, 60000);
};



function handlePreviewImages(obj) { //预览图片
    console.log(obj,789);
    
    let changeImg = []
    obj.forEach(item => {
        changeImg.push(item.src)
    })
    currentImages.value = changeImg; // 设置当前要显示的图片列表
    dialogVisibleImage.value = true; // 打开对话框
}
</script>

<style lang="scss" scoped>
/**消息-告警 */

.notification {
    position: fixed;
    top: 257px;
    right: 358px;
    padding: 10px;
    border-radius: 5px;
    text-align: left;
    z-index: 4;
    transition: opacity 0.5s;
    overflow-y: scroll;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);


}

.notice-container {
    position: fixed;
    margin-top: -216px;
    width: 360px;
    padding: 12px 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    background: #FFF;
    // box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);


    .notice-header {
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        border-bottom: 1px solid #f2f3f5;

        img {
            width: 16px;
            height: 16px;
        }
    }

    .notice-content {
        display: flex;
    }

    .notice-items {
        margin-left: 10px;

        .is-state1,
        .is-state2,
        .is-state3 {
            margin-left: 8px;
            padding: 2px 4px;
            border-radius: 4px;
            font-family: "PingFang SC";
            font-size: 12px;
            font-weight: 400;
        }

        .is-state1 {
            border: 1px solid #DF3737;
            color: #DF3737;
        }

        .is-state2 {
            border: 1px solid #F87700;
            color: #F87700;
        }

        .is-state3 {
            border: 1px solid #057C98;
            color: #057C98;
        }
    }

    .wrapper-text {
        color: #86909c;
        font-family: 'PingFang SC';
        font-size: 14px;
        font-weight: 400;
    }

    .list-data {
        display: flex;
        padding: 12px 0;

        img {
            width: 40px;
            height: 40px;
        }
    }

    .item-name {
        color: #1d2129;
        font-family: 'PingFang SC';
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
    }

    .item-state {
        margin-left: 8px;
        padding: 0 4px;
        border-radius: 4px;
        border: 1px solid #057c98;
        color: #057c98;
        font-family: 'PingFang SC';
        font-size: 12px;
        font-weight: 400;
    }

    .item-time {
        color: var(--PC-, #4e5969);
        font-family: 'PingFang SC';
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

    .item-left {
        color: var(--PC-, #86909c);
        font-family: 'PingFang SC';
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

    .item-right {
        overflow: hidden;
        color: var(--PC-, #1d2129);
        text-overflow: ellipsis;
        font-family: 'PingFang SC';
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

    .warn-btn {
        display: flex;
        justify-content: flex-end;
        padding-top: 12px;
        text-align: center;
        border-top: 1px solid var(--PC-, #F2F3F5);
        cursor: pointer;

        .warn-close {
            margin-right: 10px;
            width: 60px;
            height: 32px;
            line-height: 32px;
            border-radius: 4px;
            border: 1px solid #D9D9D9;
            background: #fff;
            color: rgba(0, 0, 0, 0.88);
            font-family: "PingFang SC";
            font-size: 14px;
            font-weight: 400;
        }

        .warn-know {
            display: flex;
            justify-content: center;
            width: 74px;
            height: 32px;
            line-height: 32px;
            border-radius: var(--4, 4px);
            background: #057C98;
            font-size: 12px;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

/**消息-平台 */
.platform-body {
    width: 360px;
    padding: 12px 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);

    .platform-header {
        img {
            margin-right: 4px;
            width: 16px;
            height: 16px;
        }

        display: flex;
        align-items: center;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--PC-, #F2F3F5);
        color: var(--PC-, #86909C);
        font-family: "PingFang SC";
        font-size: 14px;
        font-weight: 400;
    }

    .platform-container {
        padding: 12px 0;

        .platform-title {
            overflow: hidden;
            color: var(--PC-, #1D2129);
            text-overflow: ellipsis;
            font-family: "PingFang SC";
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }

        .platform-content {
            padding-top: 4px;
            color: var(--PC-, #4E5969);
            font-family: "PingFang SC";
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }
    }

    .platform-btn {
        display: flex;
        justify-content: flex-end;
        padding-top: 12px;
        text-align: center;
        border-top: 1px solid var(--PC-, #F2F3F5);
        cursor: pointer;

        .platform-know {
            display: flex;
            justify-content: center;
            width: 74px;
            height: 32px;
            line-height: 32px;
            border-radius: var(--4, 4px);
            border: 1px solid var(--Border-colorBorder, #D9D9D9);
            background: var(--Background-colorBgContainer, #FFF);
            font-size: 12px;
            color: var(--Text-colorText, rgba(0, 0, 0, 0.88));

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }
}



@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
        /* 初始状态，元素在屏幕右侧外 */
        opacity: 0;
    }

    to {
        transform: translateX(0);
        /* 动画结束，元素移动到正常位置 */
        opacity: 1;
    }
}

/* 应用动画的类 */
.slide-in-from-right {
    animation: slideInFromRight 0.5s ease-out forwards;
    /* 应用动画，持续时间 0.5 秒，不填写延迟 */
}
</style>