var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-wrapper",
      class: _vm.classObj,
      style: { "--current-color": _vm.theme },
    },
    [
      _c("sidebar", { staticClass: "sidebar-container" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { hasTagsView: _vm.needTagsView },
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [
              _c("navbar"),
              _vm._v(" "),
              _vm.needTagsView ? _c("tags-view") : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("app-main"),
          _vm._v(" "),
          _c(
            "right-panel",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
            },
            [_c("Settings")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }