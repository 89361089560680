/**
 * 创建一个支持轮询的组合式函数
 * @param {Function} callback - 轮询时执行的回调函数
 * @param {Number} interval - 轮询时间间隔（毫秒）
 */

import { ref } from 'vue';

export function usePolling1(callback, interval = 5000, immediate = false) {
    const isPolling = ref(false);
    let timerId = null;
  
    function startPolling1() {
      if (!isPolling.value) {
        isPolling.value = true;
        if (immediate) callback();
        timerId = setInterval(callback, interval);
      }
    }
  
    function stopPolling1() {
      if (isPolling.value) {
        clearInterval(timerId);
        isPolling.value = false;
      }
    }
    // 控制轮询的开关
    function togglePolling1() {
      if (isPolling.value) {
        stopPolling1();
      } else {
        startPolling1(callback);
      }
    }
  
    return {
      startPolling1,
      stopPolling1,
      togglePolling1,
      isPolling,
    };
  }

  export function usePolling2(callback, interval = 5000, immediate = false) {
    const isPolling = ref(false);
    let timerId = null;
  
    function startPolling2() {
      if (!isPolling.value) {
        isPolling.value = true;
        if (immediate) callback();
        timerId = setInterval(callback, interval);
      }
    }
  
    function stopPolling2() {
      if (isPolling.value) {
        clearInterval(timerId);
        isPolling.value = false;
      }
    }
    // 控制轮询的开关
    function togglePolling2() {
      if (isPolling.value) {
        stopPolling2();
      } else {
        startPolling2(callback);
      }
    }
  
    return {
      startPolling2,
      stopPolling2,
      togglePolling2,
      isPolling,
    };
  }