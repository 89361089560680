var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _vm.isNotificationVisible
        ? _c("showMessage", {
            attrs: { message: _vm.notificationMessage, type: "success" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "left-menu" },
        [
          _c("hamburger", {
            staticClass: "hamburger-container",
            staticStyle: { "padding-left": "0px" },
            attrs: {
              id: "hamburger-container",
              "is-active": _vm.sidebar.opened,
            },
            on: { toggleClick: _vm.toggleSideBar },
          }),
          _vm._v(" "),
          _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c("search", {
            staticClass: "right-menu-item",
            attrs: { id: "header-search" },
          }),
          _vm._v(" "),
          _c("Screenfull", {
            staticClass: "right-menu-item hover-effect",
            attrs: { id: "screenfull" },
          }),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              {
                staticStyle: { "margin-right": "7px", cursor: "pointer" },
                style: { marginRight: _vm.noticeVal == 0 ? "14px" : "7px" },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: `/message` } },
                  [
                    _c("img", {
                      staticClass: "notice-icon",
                      attrs: {
                        src: require("@/assets/message/notification.svg"),
                      },
                    }),
                    _vm._v(" "),
                    _vm.noticeVal != 0
                      ? _c("el-badge", {
                          staticClass: "notice-nums",
                          attrs: { value: _vm.noticeVal, max: 99 },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "布局大小",
                effect: "dark",
                placement: "bottom",
              },
            },
            [
              _c("size-select", {
                staticClass: "right-menu-item hover-effect",
                attrs: { id: "size-select" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("div", [
                  _c("i", { staticClass: "el-icon-arrow-down" }),
                  _vm._v("  " + _vm._s(_vm.adminName)),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: require("../../assets/touxiang.png") },
                }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: {
                          click: function ($event) {
                            return _vm.show()
                          },
                        },
                      },
                      [_vm._v("修改密码")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("退出登录")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "修改密码",
            modal: false,
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.formData, rules: _vm.formRule },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  attrs: { label: "原密码", prop: "password" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "password",
                      placeholder: "请输入原密码",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "password", $$v)
                      },
                      expression: "formData.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  attrs: { label: "新密码", prop: "password" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "password",
                      placeholder: "请输入新密码",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.newPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "newPassword", $$v)
                      },
                      expression: "formData.newPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confim } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }