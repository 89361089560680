var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "drawer-container",
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "setting-drawer-content" }, [
            _c(
              "div",
              { staticClass: "drawer-item" },
              [
                _c("span", [_vm._v("主题颜色")]),
                _vm._v(" "),
                _c("theme-picker", {
                  staticStyle: {
                    float: "right",
                    height: "26px",
                    margin: "-3px 8px 0 0",
                  },
                  on: { change: _vm.themeChange },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("h3", { staticClass: "drawer-title" }, [_vm._v("系统布局配置")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v("开启 Tags-Views")]),
              _vm._v(" "),
              _c("el-switch", {
                staticClass: "drawer-switch",
                model: {
                  value: _vm.tagsView,
                  callback: function ($$v) {
                    _vm.tagsView = $$v
                  },
                  expression: "tagsView",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v("固定 Header")]),
              _vm._v(" "),
              _c("el-switch", {
                staticClass: "drawer-switch",
                model: {
                  value: _vm.fixedHeader,
                  callback: function ($$v) {
                    _vm.fixedHeader = $$v
                  },
                  expression: "fixedHeader",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v("显示 Logo")]),
              _vm._v(" "),
              _c("el-switch", {
                staticClass: "drawer-switch",
                model: {
                  value: _vm.sidebarLogo,
                  callback: function ($$v) {
                    _vm.sidebarLogo = $$v
                  },
                  expression: "sidebarLogo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                icon: "el-icon-document-add",
              },
              on: { click: _vm.saveSetting },
            },
            [_vm._v("保存配置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { plain: "", icon: "el-icon-refresh" },
              on: { click: _vm.resetSetting },
            },
            [_vm._v("重置配置")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }